<script setup>
import atrLogo from "@images/logos/atr.png";

import { passwordValidator } from "@/@core/utils/validators";
import { useAuthStore } from "@/composables/store/useAuthStore";
import authV2RegisterIllustrationBorderedDark from "@images/pages/auth-v2-register-illustration-bordered-dark.png";
import authV2RegisterIllustrationBorderedLight from "@images/pages/auth-v2-register-illustration-bordered-light.png";
import authV2RegisterIllustrationDark from "@images/pages/auth-v2-register-illustration-dark.png";
import authV2RegisterIllustrationLight from "@images/pages/auth-v2-register-illustration-light.png";
import authV2MaskDark from "@images/pages/misc-mask-dark.png";
import authV2MaskLight from "@images/pages/misc-mask-light.png";

const router = useRouter();
const route = useRoute();

const imageVariant = useGenerateImageVariant(
  authV2RegisterIllustrationLight,
  authV2RegisterIllustrationDark,
  authV2RegisterIllustrationBorderedLight,
  authV2RegisterIllustrationBorderedDark,
  true
);
const authThemeMask = useGenerateImageVariant(authV2MaskLight, authV2MaskDark);

definePage({
  meta: {
    layout: "blank",
    unauthenticatedOnly: true,
  },
});

// Get random name
const randomId = () => {
  return Math.random().toString(36).substr(2, 3) + "Agency";
};

const random = randomId();

// Get url params and set default values
const {
  company_name: routeCompanyName,
  user_email: routeUserEmail,
  company_type: routeCompanyType,
  company_website: routeCompanyWebsite,
  user_type: routeUserType,
} = route.query;

const env = import.meta.env.VITE_ENV;

const form = ref({
  name: env === "development" ? random : "",
  email:
    routeUserEmail || (env === "development" ? `${random}@example.com` : ""),
  password: env === "development" ? "Soccer12" : "",
  companyName: routeCompanyName || (env === "development" ? random : ""),
  companyWebsite:
    routeCompanyWebsite || (env === "development" ? random + ".com" : ""),
  companyType: routeCompanyType || "agency",
  userType: routeUserType || null,
  privacyPolicies: false,
});

const { isSignupLoading, updateSignupUserData, signUp, signup } =
  useAuthStore();
const refVForm = ref(null);

const isPasswordVisible = ref(false);

const onSubmit = async () => {
  const isValid = await refVForm.value.validate();
  if (!isValid.valid) return;

  updateSignupUserData({
    name: form.value.name,
    email: form.value.email,
    password: form.value.password,
    company_name: form.value.companyName,
    company_website: form.value.companyWebsite,
    company_type: form.value.companyType,
  });

  await signUp();

  await nextTick(() => {
    console.log("router", router);
    router.replace(route.query.to ? String(route.query.to) : "/");
  });
};
</script>

<template>
  <VForm ref="refVForm" @submit.prevent="onSubmit">
    <VRow no-gutters class="auth-wrapper bg-surface">
      <VCol
        md="7"
        class="d-none d-md-flex"
        style="
          height: 100vh;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgb(var(--v-theme-primary));

          color: white;
          padding: 20px;
        "
      >
        <div style="text-align: left; max-width: 600px">
          <p style="font-size: 48px; margin-bottom: 20px; font-weight: 400">
            Start Your Free 14-Day Trial Today<br />
            No Credit Card, No Commitment
            <br />
            <span class="font-bold italic">Just Results</span>
          </p>
          <p style="font-size: 18px; margin-bottom: 40px">
            Experience the power of seamless client onboarding. Sign up now to
            streamline access, improve workflows, and manage clients
            effortlessly.
          </p>
        </div>
      </VCol>

      <VCol
        cols="12"
        md="4"
        class="auth-card-v2 d-flex align-center justify-center"
        style="background-color: rgb(var(--v-theme-surface))"
      >
        <VCard flat :max-width="500" class="mt-12 mt-sm-0 pa-4">
          <VCardText>
            <VImg class="pb-5" max-width="200px" :src="atrLogo" />
            <p class="mb-0">Create account to continue.</p>
          </VCardText>

          <VCardText>
            <VRow>
              <!-- Username -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.name"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator]"
                  autofocus
                  label="Name"
                  placeholder="Johndoe"
                />
              </VCol>

              <!-- email -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.email"
                  :disabled="isSignupLoading || routeUserEmail"
                  :rules="[requiredValidator, emailValidator]"
                  label="Email"
                  type="email"
                  placeholder="johndoe@email.com"
                />
              </VCol>

              <!-- Company Name -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.companyName"
                  :disabled="isSignupLoading || routeCompanyName"
                  :rules="[requiredValidator]"
                  label="Company Name"
                  placeholder="John Company Co."
                />
              </VCol>

              <!-- Company Website -->
              <VCol v-if="form.userType !== 'invited'" cols="12">
                <AppTextField
                  v-model="form.companyWebsite"
                  :disabled="isSignupLoading || routeCompanyWebsite"
                  :rules="[requiredValidator]"
                  label="Company Website"
                  placeholder="attherate.com"
                />
              </VCol>

              <!-- password -->
              <VCol cols="12">
                <AppTextField
                  v-model="form.password"
                  :disabled="isSignupLoading"
                  :rules="[requiredValidator, passwordValidator]"
                  label="Password"
                  placeholder="············"
                  :type="isPasswordVisible ? 'text' : 'password'"
                  :append-inner-icon="
                    isPasswordVisible ? 'tabler-eye-off' : 'tabler-eye'
                  "
                  @click:append-inner="isPasswordVisible = !isPasswordVisible"
                />
              </VCol>

              <VCol cols="12">
                <div class="d-flex align-center mt-2 mb-4">
                  <VCheckbox
                    id="privacy-policy"
                    :rules="[requiredValidator]"
                    v-model="form.privacyPolicies"
                    inline
                  >
                    <template v-slot:label>
                      <VLabel for="privacy-policy" style="opacity: 1">
                        <span target="_blank" class="me-1">I agree to</span>
                        <a
                          href="https://www.attherate.ai/privacy-policy"
                          target="_blank"
                          class="text-primary"
                          >privacy policy</a
                        >
                        <span class="mx-1">and</span>
                        <a
                          href="https://www.attherate.ai/terms-of-use"
                          target="_blank"
                          class="text-primary"
                          >terms of use</a
                        >
                      </VLabel>
                    </template>
                  </VCheckbox>
                </div>
                <VBtn :loading="signup.isLoading" block type="submit">
                  Sign up
                </VBtn>
              </VCol>

              <!-- create account -->
              <VCol cols="12" class="text-center text-base">
                <span>Already have an account?</span>
                <RouterLink class="text-primary ms-2" :to="{ name: 'login' }">
                  Sign in instead
                </RouterLink>
              </VCol>

              <!-- <VCol cols="12" class="d-flex align-center">
                <VDivider />
                <span class="mx-4">or</span>
                <VDivider />
              </VCol> -->

              <!-- auth providers -->
              <!-- <VCol cols="12" class="text-center">
                <AuthProvider />
              </VCol> -->
            </VRow>
          </VCardText>
        </VCard>
      </VCol>
    </VRow>
  </VForm>
</template>

<style lang="scss">
@use "@core/scss/template/pages/page-auth.scss";
</style>
