<script setup>
import Footer from "@/views/front-pages/front-page-footer.vue";
import Navbar from "@/views/front-pages/front-page-navbar.vue";
import AddressContent from "@/views/wizard-examples/checkout/Address.vue";
import CartContent from "@/views/wizard-examples/checkout/Cart.vue";
import ConfirmationContent from "@/views/wizard-examples/checkout/Confirmation.vue";
import PaymentContent from "@/views/wizard-examples/checkout/Payment.vue";
import googleHome from "@images/pages/google-home.png";
import iphone11 from "@images/pages/iphone-11.png";

definePage({ meta: { layout: "blank" } });

const checkoutSteps = [
  {
    title: "Cart",
    icon: "custom-cart",
  },
  {
    title: "Address",
    icon: "custom-address",
  },
  {
    title: "Payment",
    icon: "custom-payment",
  },
  {
    title: "Confirmation",
    icon: "custom-trending",
  },
];

const checkoutData = ref({
  cartItems: [
    {
      id: 1,
      name: "Google - Google Home - White",
      seller: "Google",
      inStock: true,
      rating: 4,
      price: 299,
      discountPrice: 359,
      image: googleHome,
      quantity: 1,
      estimatedDelivery: "18th Nov 2021",
    },
    {
      id: 2,
      name: "Apple iPhone 11 (64GB, Black)",
      seller: "Apple",
      inStock: true,
      rating: 4,
      price: 899,
      discountPrice: 999,
      image: iphone11,
      quantity: 1,
      estimatedDelivery: "20th Nov 2021",
    },
  ],
  promoCode: "",
  orderAmount: 1198,
  deliveryAddress: "home",
  deliverySpeed: "free",
  deliveryCharges: 0,
  addresses: [
    {
      title: "John Doe (Default)",
      desc: "4135 Parkway Street, Los Angeles, CA, 90017",
      subtitle: "1234567890",
      value: "home",
    },
    {
      title: "ACME Inc.",
      desc: "87 Hoffman Avenue, New York, NY, 10016",
      subtitle: "1234567890",
      value: "office",
    },
  ],
});

const currentStep = ref(0);
</script>

<template>
  <div class="checkout-page">
    <Navbar />
    <VContainer>
      <div class="checkout-card">
        <VCard>
          <VCardText>
            <!-- 👉 Stepper -->
            <AppStepper
              v-model:current-step="currentStep"
              class="checkout-stepper"
              :items="checkoutSteps"
              :direction="$vuetify.display.mdAndUp ? 'horizontal' : 'vertical'"
            />
          </VCardText>
          <VDivider />
          <VCardText>
            <!-- 👉 stepper content -->
            <VWindow
              v-model="currentStep"
              class="disable-tab-transition"
              :touch="false"
            >
              <VWindowItem>
                <CartContent
                  v-model:current-step="currentStep"
                  v-model:checkout-data="checkoutData"
                />
              </VWindowItem>
              <VWindowItem>
                <AddressContent
                  v-model:current-step="currentStep"
                  v-model:checkout-data="checkoutData"
                />
              </VWindowItem>
              <VWindowItem>
                <PaymentContent
                  v-model:current-step="currentStep"
                  v-model:checkout-data="checkoutData"
                />
              </VWindowItem>
              <VWindowItem>
                <ConfirmationContent v-model:checkout-data="checkoutData" />
              </VWindowItem>
            </VWindow>
          </VCardText>
        </VCard>
      </div>
    </VContainer>
    <Footer />
  </div>
</template>

<style lang="scss">
.checkout-stepper {
  .stepper-icon-step {
    .step-wrapper + svg {
      margin-inline: 3.5rem !important;
    }
  }
}

.checkout-card {
  margin-block-end: 6.25rem;
  margin-block-start: 9.75rem;
}

@media (max-width: 960px) and (min-width: 600px) {
  .checkout-page {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .checkout-card {
    margin-block-start: 6rem;
  }
}
</style>
