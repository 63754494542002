<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const authStore = useAuthStore();

const saveMetaAuthToken = async () => {
  setTimeout(() => {
    console.log("redirecting to clients-settings");
    if (authStore.company.company_type === "agency") {
      router.push({
        name: "pages-request-access",
      });
    } else {
      router.push({
        name: "pages-client-request-access",
      });
    }
    authStore.getCompany(authStore.company.id);
  }, 3500);
};

onMounted(async () => {
  authStore.getCompany(authStore.company.id);
  await saveMetaAuthToken();
});
</script>

<template>
  <div class="text-center" style="margin-top: 200px">
    <h1 class="mb-10">Redirecting back to app</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
