import ability, { defineAbilitiesFor } from "@/plugins/casl/ability";
import { apiInstance, sendGraphQLRequest } from "@/utils/axios";
import { LOCALSTORAGE } from "@/utils/constants";
import * as Sentry from "@sentry/vue";
import { useStorage } from "@vueuse/core";
import { jwtDecode } from "jwt-decode";
import { defineStore } from "pinia";

export const useAuthStore = defineStore({
  id: "authStore",
  state: () => ({
    hasuraAuthToken:
      localStorage.getItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN) || null,
    signup: {
      user: {
        name: "",
        email: "",
        password: "",
        company_name: "",
        company_website: "",
        company_type: "",
      },
      isLoading: false,
      response: null,
    },
    signin: {
      user: {
        email: "",
        password: "",
      },
      isLoading: false,
      response: null,
    },
    user: {
      id: localStorage.getItem(LOCALSTORAGE.USER_ID) || null,
    },
    company: {
      id: localStorage.getItem(LOCALSTORAGE.COMPANY_ID) || null,
      company_type: localStorage.getItem(LOCALSTORAGE.COMPANY_TYPE) || null,
    },
    subscription: {
      id: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_ID) || null,
      status: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_STATUS) || null,
      key: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_KEY) || null,
      startDate:
        localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_START_DATE) || null,
      endDate: localStorage.getItem(LOCALSTORAGE.SUBSCRIPTION_END_DATE) || null,
    },
    currentAgency: {},
    profilePicture: "",

    isForgetPasswordLoading: false,
    isResetPasswordLoading: false,
    isCreateCompanyDrawerOpen: false,
    isCreateCompanyLoading: false,
    createCompanyForm: {
      name: "",
      email: "",
      website: "",
    },
    createUserForm: {
      name: "",
    },
    isCreateUserLoading: false,
    isCreateUserDrawerOpen: false,
    isConfirmCancelSubscriptionDrawerOpen: false,
    isConfirmCancelSubscriptionLoading: false,
    confirmCancelSubscriptionForm: {
      feedback: "",
      featureRequest: "",
    },
    invoices: [],
  }),
  actions: {
    async signUp() {
      try {
        this.signup.isLoading = true;
        const response = await apiInstance.post(
          `/api/signup`,
          {
            formFields: [
              { id: "name", value: this.signup.user.name },
              { id: "email", value: this.signup.user.email },
              { id: "password", value: this.signup.user.password },
              { id: "company_name", value: this.signup.user.company_name },
              {
                id: "company_website",
                value: this.signup.user.company_website,
              },
              { id: "company_type", value: this.signup.user.company_type },
            ],
          },
          {
            headers: {
              rid: "emailpassword",
              "Content-Type": "application/json",
            },
          }
        );

        this.signup.response = response.data;

        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      } catch (error) {
        this.signup.response = null;
      } finally {
        this.signup.isLoading = false;
      }
    },
    async signIn() {
      // const posthog = inject("posthog");
      this.signin.isLoading = true;
      try {
        const response = await apiInstance.post(
          `/api/signin`,
          {
            formFields: [
              { id: "email", value: this.signin.user.email },
              { id: "password", value: this.signin.user.password },
            ],
          },
          {
            headers: {
              rid: "emailpassword",
              "Content-Type": "application/json",
            },
          }
        );

        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        this.signin.response = response.data;

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        posthog?.identify(this.user?.id, {
          email: this.user?.email,
          name: this.user?.name,
          company: this.company?.name,
          company_type: this.company?.company_type,
        });

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      } catch (error) {
        this.signin.response = null;
      } finally {
        this.signin.isLoading = false;
      }
    },
    updateSignupUserData(payload) {
      this.signup.user = { ...this.signup.user, ...payload };
    },
    updateSigninUserData(payload) {
      this.signin.user = { ...this.signin.user, ...payload };
    },
    initAuthData() {
      if (
        this.hasuraAuthToken &&
        this.user?.id &&
        this.company?.id &&
        !this.user?.name &&
        !this.company?.name
      ) {
        Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]).then((values) => {
          this.user = values[0].data?.users_by_pk;
          this.company = values[1].data?.companies_by_pk;
          localStorage.setItem(
            LOCALSTORAGE.COMPANY_TYPE,
            this.company?.company_type
          );

          Sentry.withScope((scope) => {
            scope.setUser({
              id: this.user?.id,
              user: this.user,
              company: this?.company,
            });
          });
        });
      }
    },
    setAuthToken(token) {
      try {
        if (!token) return;
        this.authToken = token;
        localStorage.setItem(LOCALSTORAGE.HASURA_ACCESS_TOKEN, token);

        const decodedToken = jwtDecode(token);
        const userId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
        const companyId =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-company-id"];
        const subscriptionStatus =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-status"
          ];
        const subscriptionKey =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-key"
          ];
        const subscriptionStartDate =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-start-date"
          ];
        const subscriptionEndDate =
          decodedToken["https://hasura.io/jwt/claims"][
            "x-hasura-subscription-end-date"
          ];
        const userRole =
          decodedToken["https://hasura.io/jwt/claims"]["x-hasura-default-role"];
        localStorage.setItem(LOCALSTORAGE.USER_ID, userId);
        localStorage.setItem(LOCALSTORAGE.COMPANY_ID, companyId);
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_STATUS,
          subscriptionStatus
        );
        localStorage.setItem(LOCALSTORAGE.USER_ROLE, userRole);
        localStorage.setItem(LOCALSTORAGE.SUBSCRIPTION_KEY, subscriptionKey);
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_START_DATE,
          subscriptionStartDate
        );
        localStorage.setItem(
          LOCALSTORAGE.SUBSCRIPTION_END_DATE,
          subscriptionEndDate
        );

        const newAbility = defineAbilitiesFor(userRole);
        console.log(
          "🚀 ~ file: useAuthStore.js ~ line 191 ~ setAuthToken ~ ability",
          newAbility
        );
        useStorage("userAbilityRules").value = newAbility.rules;
        ability.update(newAbility.rules);

        this.user = {
          ...this.user,
          id: userId,
        };
        this.company = {
          ...this.company,
          id: companyId,
        };

        Sentry.withScope((scope) => {
          scope.setUser({
            id: this.user?.id,
            user: this.user,
            company: this?.company,
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    logout() {
      localStorage.clear();
      // useCookie("userAbilityRules").value = [];
      this.signup.user = {
        name: "",
        email: "",
        password: "",
        company_name: "",
        company_website: "",
        company_type: "",
      };
      this.signin.user = { email: "", password: "" };
    },
    async getUserData() {
      const query = `
        query {
          users {
            email
            name
            user_pk
            user_type
            created_at
            updated_at
          }
        }
      `;
      return sendGraphQLRequest(query);
    },
    async getUserById(userId) {
      const query = `
        query GetUser($user_id: uuid!) {
          users_by_pk(user_pk: $user_id) {
            company {
              company_pk
              company_type
              name
              website
              created_at
              updated_at
          }
          email
          name
          user_pk
          user_type
          created_at
          updated_at
        }
      }
      `;
      return sendGraphQLRequest(query, { user_id: userId });
    },
    async getAgencyCompanyData() {
      const query = `
        query {
          companies {
            company_pk
            company_type
            name
            website
            created_at
            updated_at
          }
      `;
      return sendGraphQLRequest(query);
    },
    async getCompany(companyId) {
      const query = `
      query GetCompany($company_id: uuid!) {
        companies_by_pk(company_pk: $company_id) {
          company_pk
          company_type
          profile_picture_url
          name
          email
          website
          created_at
          updated_at
          is_google_ads_connected
          is_meta_ads_connected
          is_shopify_connected
          subscription_transactions(where: {status: {_eq: "active"}}, limit: 1, order_by: {updated_at: desc}) {
            subscription_tier {
              tier_name
              key
            }
            start_date
            end_date
            status
            updated_at
          }
          users {
            email
            name
            user_pk
            user_type
            created_at
            updated_at
          }
        }
      }
      `;
      return sendGraphQLRequest(query, { company_id: companyId });
    },

    async getAgencyProfilePicture(companyId) {
      try {
        const response = await apiInstance.post(
          `/onboarding/get-company-profile-picture`,
          {
            companyId: companyId,
            status: "public",
          }
        );
        console.log("pp", response);
        this.profilePicture = response.data.data;
        return response.data.data;
      } catch (error) {
        console.error("Error fetching dashboards", error);
      }
    },

    async getCompanyProfilePicture(companyId) {
      try {
        const query = `
   query GetCompanyProfilePicture($company_id: uuid!) {
        companies_by_pk(company_pk: $company_id) {
      profile_picture_url
      }
  }
    `;
        const data = await sendGraphQLRequest(query, {
          company_id: companyId,
        });
        console.log("data:", data);
        this.profilePicture = data.data.companies_by_pk;
        return data.data.companies_by_pk;
      } catch (error) {
        console.error("Error fetching dashboards", error);
      }
    },

    async googleSignIn() {
      const response = await apiInstance.post("/api/auth/signup/google");
      window.open(response.data.url, "_self");
      return response;
    },
    async googleCallback(code) {
      const response = await apiInstance.post("/api/auth/callback/google", {
        code,
      });
      if (response.data.redirect === "login") {
        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }

        const [userData, companyData] = await Promise.all([
          this.getUserById(this.user?.id),
          this.getCompany(this.company?.id),
        ]);

        this.user = userData.data?.users_by_pk;
        this.company = companyData.data?.companies_by_pk;
        localStorage.setItem(
          LOCALSTORAGE.COMPANY_TYPE,
          this.company?.company_type
        );
      }

      return response;
    },
    async forgetPassword(email) {
      try {
        this.isForgetPasswordLoading = true;
        const response = await apiInstance.post("/api/auth/reset-password", {
          email,
        });
        this.isForgetPasswordLoading = false;
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isForgetPasswordLoading = false;
      }
    },
    async resetPassword({ email, token, newPassword }) {
      try {
        this.isResetPasswordLoading = true;
        const response = await apiInstance.post(
          "/api/auth/reset-password/token",
          { email, token, password: newPassword }
        );
        this.isResetPasswordLoading = false;

        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isResetPasswordLoading = false;
      }
    },
    async getCompanyDetails() {
      try {
        const companyDetails = await this.getCompany(
          this.company?.id || this.company.company_pk
        );
        this.company = {
          ...this.company,
          ...companyDetails.data.companies_by_pk,
        };
      } catch (error) {
        console.log(error);
      }
    },
    async isValidSubscription() {
      const previousSubscriptionKey = this.subscription.key;
      const companyResponse = await this.getCompany(
        this.company.id || this.company.company_pk
      );
      console.log(
        "🚀 ~ isValidSubscription ~ companyResponse:",
        companyResponse
      );
      console.log(
        companyResponse?.data?.companies_by_pk?.subscription_transactions[0]
          .subscription_tier.key,
        previousSubscriptionKey
      );

      if (
        previousSubscriptionKey !==
        companyResponse?.data?.companies_by_pk?.subscription_transactions[0]
          .subscription_tier.key
      ) {
        return false;
      } else {
        return true;
      }
    },
    async updateCompanyDetails() {
      this.isCreateCompanyLoading = true;
      try {
        await apiInstance.put(`/company`, {
          name: this.createCompanyForm.name,
          email: this.createCompanyForm.email,
          website: this.createCompanyForm.website,
        });
        await this.getCompanyDetails();
        await this.regenerateToken();
        this.isCreateCompanyDrawerOpen = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isCreateCompanyLoading = false;
      }
    },
    async updateUserDetails() {
      this.isCreateUserLoading = true;
      try {
        await apiInstance.put(`/user`, {
          name: this.createUserForm.name,
        });
        const user = await this.getUserById(this.user.id || this.user.user_pk);
        await this.regenerateToken();
        this.user = {
          ...this.user,
          ...user.data.users_by_pk,
        };
        this.isCreateUserDrawerOpen = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isCreateUserLoading = false;
      }
    },
    async regenerateToken() {
      try {
        const response = await apiInstance.post("/api/regenerate-token");
        const hasuraAuthToken = response.headers["st-access-token"];
        if (hasuraAuthToken) {
          this.setAuthToken(hasuraAuthToken);
        }
        return response;
      } catch (error) {
        console.log(error);
      }
    },
    async cancelSubscription() {
      try {
        this.isConfirmCancelSubscriptionLoading = true;
        const response = await apiInstance.post("/subscription/cancel", {
          feedback: this.confirmCancelSubscriptionForm.feedback,
          featureRequest: this.confirmCancelSubscriptionForm.featureRequest,
        });
        await this.regenerateToken();
        await this.getCompanyDetails();
        this.isConfirmCancelSubscriptionLoading = false;
        return response;
      } catch (error) {
        console.log(error);
      } finally {
        this.isConfirmCancelSubscriptionLoading = false;
      }
    },
    // Get company invoice /subscription/invoices
    async getSubscriptionInvoices() {
      try {
        const response = await apiInstance.get("/subscription/invoices");
        console.log(
          "🚀 ~ file: useAuthStore.js ~ line 327 ~ useAuthStore ~ getSubscriptionInvoices ~ response",
          response
        );
        this.invoices = response.data.data;
        return response;
      } catch (error) {
        console.log(error);
      }
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.authToken,
    getSignupResponse: (state) => state.signup.response,
    getSigninResponse: (state) => state.signin.response,
    isSignupLoading: (state) => state.signup.isLoading,
    isSigninLoading: (state) => state.signin.isLoading,
    getAgencies: (state) => state.company.clients,
    companySubscription: (state) =>
      state.company?.subscription_transactions?.[0],
    leftoverSubscriptionDays: (state) => {
      const endDate = new Date(
        state.company?.subscription_transactions?.[0]?.end_date
      );
      if (!endDate) return 0;
      const currentDate = new Date();
      const diffTime = endDate - currentDate;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    },
  },
});
