<template>
  <div class="p-10">
    <PageHeader :title="pageTitle" :description="pageDescription">
      <!-- You can add action buttons here if needed -->
    </PageHeader>
    <div class="report-container">
      <iframe
        :src="`https://lookerstudio.google.com/embed/reporting/${route.params.id}`"
        frameborder="0"
        allowfullscreen
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from "vue-router";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const route = useRoute();

const pageTitle = "Analytics Report";
const pageDescription =
  "Explore detailed insights and analytics in this comprehensive report.";

// If you have access to the report name or additional data, you can set the title dynamically
// const pageTitle = computed(() => `Report: ${route.params.reportName || 'Analytics Report'}`);
</script>

<style scoped>
.report-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.report-container iframe {
  width: 100%;
  height: 80vh;
  border: none;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>
