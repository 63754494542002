<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useErrorStore } from "@/composables/store/useErrorStore";
import { useSocialConnection } from "@/composables/store/useSocialConnection";
import { onMounted } from "vue";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const route = useRoute();
const router = useRouter();
const socialConnection = useSocialConnection();
const authStore = useAuthStore();

const agencyId = JSON.parse(decodeURIComponent(route.query?.state))?.agency_id;
const clientId = JSON.parse(decodeURIComponent(route.query?.state))?.client_id;
const status = JSON.parse(decodeURIComponent(route.query?.state))?.status;
const urlId = JSON.parse(decodeURIComponent(route.query?.state))?.url_id;
const urlToken = JSON.parse(decodeURIComponent(route.query?.state))?.url_token;

const saveGoogleAuthToken = async () => {
  try {
    if (!route?.query?.code) {
      useErrorStore().setError("Invalid Google OAuth code");
      return;
    }

    // Finish Google OAuth process
    const oAuthResponse = await socialConnection.finishGoogleOAuth(
      route.query.code,
      agencyId,
      clientId,
      status
    );

    // If there's an issue with the response
    if (!oAuthResponse) {
      useErrorStore().setError("Failed to connect to Google.");
      return;
    }

    const reDirectionStatus =
      oAuthResponse && oAuthResponse.status ? oAuthResponse.status : null;

    // Redirect based on the status (public or private)
    router.push({
      name: "googleads-manage-connection",
      query: {
        state: route.query.state,
        status: reDirectionStatus === "public" ? "public" : "private",
      },
    });
  } catch (error) {
    // Handle any unexpected errors
    useErrorStore().setError("An error occurred while connecting.");
  }
};

onMounted(() => {
  saveGoogleAuthToken();
});
</script>

<template>
  <div class="text-center" style="margin-top: 200px">
    <h1 class="mb-10">Wait till we connect to your account.</h1>
    <VProgressCircular size="100" indeterminate color="primary" />
  </div>
</template>
