<script setup>
import AppStepper from "@/@core/components/AppStepper.vue";
import OnboardConfirmationCard from "@/components/cards/OnboardConfirmationCard.vue";
import OnboardDetailsCard from "@/components/OnboardDetailsCard.vue";
import { useAgencyStore } from "@/composables/store/useAgencyStore";
import { useRequestAccessStore } from "@/composables/store/useRequestAccessStore";
import OnboardRequestAccessTable from "@/views/demos/forms/tables/data-table/OnboardRequestAccessTable.vue";
import atrLogo from "@images/logos/atr.png";
import { storeToRefs } from "pinia";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const router = useRouter();
const route = useRoute();

const currentStep = ref(0);
const isLoading = ref(true);
const isStepperEditable = ref(false);

const agencyStore = useAgencyStore();
const requestAccessStore = useRequestAccessStore();
const { companyDetails } = storeToRefs(agencyStore);

const name = ref("");
const email = ref("");
const agencyId = ref(null);
const clientEmail = ref(null);
const clientName = ref(null);
const socialMedia = ref([]);

const checkTokenAndSetStep = async () => {
  try {
    isLoading.value = true;

    // Recheck token on step change
    const tokenExists = await requestAccessStore.checkOnboardTokenExist(
      route.query.token
    );

    if (tokenExists && tokenExists.length > 0) {
      isStepperEditable.value = true;

      const token = route.query.token;
      const verifiedToken = await agencyStore.verifyPublicLinkToken(token);

      agencyId.value = verifiedToken?.decoded?.agencyId;
      clientEmail.value = verifiedToken?.decoded?.clientEmail;
      clientName.value = verifiedToken?.decoded?.clientName;
      socialMedia.value = verifiedToken?.decoded?.socialMedia;

      await requestAccessStore.getOnboardingCompanyDetails(agencyId.value);
      name.value = clientName.value || "";
      email.value = clientEmail.value || "";
    } else {
      currentStep.value = 0; // Reset to step 0 if token doesn't exist
    }
  } catch (error) {
    console.error("Error during token verification", error);
    currentStep.value = 0;
  } finally {
    isLoading.value = false;
  }
};

// Initialize the check when the component loads
const init = async () => {
  await checkTokenAndSetStep();
};

init();

// Watch for changes to currentStep and recheck token validity on step change
watch(currentStep, async (newStep) => {
  if (newStep === 1 || newStep === 2) {
    await checkTokenAndSetStep();
  }
});

const checkoutSteps = [
  {
    title: "Onboard Details",
    icon: "tabler-square-number-1",
  },
  {
    title: "Grant Access",
    icon: "tabler-square-number-2",
  },
  {
    title: "Confirmation",
    icon: "tabler-square-number-3",
  },
];

const checkoutData = ref({
  cartItems: [],
  promoCode: "",
  orderAmount: 0,
  deliveryAddress: "home",
  deliverySpeed: "free",
  deliveryCharges: 0,
  addresses: [],
});
</script>

<template>
  <div class="fixed left-4 z-10 p-4 bg-white">
    <VImg width="195px" :src="atrLogo" />
  </div>

  <VOverlay v-if="isLoading" :opacity="0.8" absolute>
    <VProgressCircular indeterminate size="64" />
  </VOverlay>

  <div class="flex flex-col min-h-screen">
    <!-- Main content with loading overlay -->
    <VContainer class="my-auto mx-auto flex-1">
      <div class="checkout-card relative">
        <VCard :loading="requestAccessStore.isRequestAccessLoading">
          <VCardText>
            <AppStepper
              v-model:current-step="currentStep"
              class="checkout-stepper"
              :items="checkoutSteps"
              :direction="$vuetify.display.mdAndUp ? 'horizontal' : 'vertical'"
              :disabled-steps="!isStepperEditable"
            />
          </VCardText>
          <VDivider />
          <VCardText>
            <VWindow
              v-model="currentStep"
              class="disable-tab-transition"
              :touch="false"
            >
              <VWindowItem>
                <OnboardDetailsCard v-model:current-step="currentStep" />
              </VWindowItem>
              <VWindowItem>
                <OnboardRequestAccessTable v-model:current-step="currentStep" />
              </VWindowItem>
              <VWindowItem>
                <OnboardConfirmationCard v-model:current-step="currentStep" />
              </VWindowItem>
            </VWindow>
          </VCardText>

          <!-- Loading overlay when isLoading is true -->
          <div
            v-if="isLoading"
            class="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75"
          >
            <VSkeletonLoader type="card, list-item" />
          </div>
        </VCard>
      </div>
    </VContainer>

    <footer class="w-full mt-10 p-6 bg-gray-100 border-t border-gray-300">
      <div
        class="max-w-7xl mx-auto flex flex-col md:flex-row items-center justify-between"
      >
        <div class="mb-4 md:mb-0">
          <VImg width="150px" :src="atrLogo" />
        </div>

        <div class="flex space-x-6">
          <a
            href="https://www.attherate.ai/terms-of-use"
            target="_blank"
            class="text-gray-700 hover:text-blue-600 transition"
            >Terms and Conditions</a
          >
          <a
            href="https://www.attherate.ai/privacy-policy"
            target="_blank"
            class="text-gray-700 hover:text-blue-600 transition"
            >Privacy Policy</a
          >
          <a
            href="https://www.attherate.ai/security-q-a"
            target="_blank"
            class="text-gray-700 hover:text-blue-600 transition"
            >Security & QA</a
          >
        </div>
      </div>
    </footer>
  </div>
</template>

<style scoped>
.checkout-stepper {
  .stepper-icon-step {
    .step-wrapper + svg {
      margin-inline: 3.5rem !important;
    }
  }
}

.checkout-card {
  margin-block-end: 3rem;
  margin-block-start: 4rem;
}

@media (max-width: 960px) and (min-width: 600px) {
  .checkout-page {
    .v-container {
      padding-inline: 2rem !important;
    }
  }
}

@media (max-width: 600px) {
  .checkout-card {
    margin-block-start: 6rem;
  }
}
</style>
