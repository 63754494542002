<script setup>
import { useAuthStore } from "@/composables/store/useAuthStore";
import { useReportStore } from "@/composables/store/useReportStore";
import { useRequestAccessStore } from "@/composables/store/useRequestAccessStore";
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

definePage({
  meta: {
    layout: "blank",
    public: true,
  },
});

const route = useRoute();
const router = useRouter();

const agencyId = ref(null);
const clientId = ref(null);
const status = ref(null);
const urlId = ref(null);
const urlToken = ref(null);

const requestAccessStore = useRequestAccessStore();
const reportStore = useReportStore();
const authstore = useAuthStore();

const { googleAdsAccounts, googleAnalyticsAccounts } = storeToRefs(reportStore);
const { isCreateManageReqAccessAccountsDrawerOpen } =
  storeToRefs(requestAccessStore);
const { mode } = storeToRefs(reportStore);

const refVForm = ref(null);
const selectedAccounts = ref(null);
const selectedGoogleAnalyticsAccounts = ref(null);

const drawerState = computed({
  get: () => isCreateManageReqAccessAccountsDrawerOpen.value,
  set: (value) =>
    (requestAccessStore.isCreateManageReqAccessAccountsDrawerOpen = value),
});

// Loading states for each button
const googleAdsLoading = ref(false);
const googleAnalyticsLoading = ref(false);
const submitAllLoading = ref(false);

const onGoogleAdsClick = async () => {
  googleAdsLoading.value = true;
  try {
    if (selectedAccounts.value && selectedAccounts.value.length > 0) {
      try {
        await reportStore.updateConnectedAccountStatus(
          selectedAccounts.value,
          "googleAds",
          clientId.value,
          status.value
        );
      } catch (error) {
        console.error("Error updating account status:", error);
      }
    }
  } finally {
    googleAdsLoading.value = false;
  }
};

const onGoogleAnalyticsClick = async () => {
  googleAnalyticsLoading.value = true;
  try {
    if (
      selectedGoogleAnalyticsAccounts.value &&
      selectedGoogleAnalyticsAccounts.value.length > 0
    ) {
      await reportStore.updateConnectedAccountStatus(
        selectedGoogleAnalyticsAccounts.value,
        "googleAnalytics",
        clientId.value,
        status.value
      );
    }
  } finally {
    googleAnalyticsLoading.value = false;
  }
};

const onSubmitAll = async () => {
  submitAllLoading.value = true;
  try {
    if (route.query.status === "public") {
      router.push({
        name: "pages-connect-id",
        params: { id: urlId.value },
        query: { token: urlToken.value },
      });
    } else {
      if (authstore.company.company_type === "agency") {
        router.push({ name: "pages-request-access" });
      } else {
        router.push({ name: "pages-client-request-access" });
      }
    }
  } finally {
    submitAllLoading.value = false;
  }
};

const init = async () => {
  let parsedState = null;

  try {
    parsedState = JSON.parse(decodeURIComponent(route.query?.state));
  } catch (error) {
    console.error("Error parsing state:", error);
    return;
  }

  if (parsedState) {
    agencyId.value = parsedState?.agency_id;
    clientId.value = parsedState?.client_id;
    urlId.value = parsedState?.url_id;
    urlToken.value = parsedState?.url_token;
    status.value = route.query.status;
  }

  const accountId = clientId.value || agencyId.value;

  if (route.query.status === "public") {
    await reportStore.getConnectedAccountsByCompany(clientId.value);
  } else {
    await reportStore.getConnectedAccountsByCompany(accountId);
  }

  if (googleAdsAccounts.value && googleAdsAccounts.value.length > 0) {
    selectedAccounts.value = googleAdsAccounts.value.map(
      (account) => account.account_id
    );
  }

  if (
    googleAnalyticsAccounts.value &&
    googleAnalyticsAccounts.value.length > 0
  ) {
    selectedGoogleAnalyticsAccounts.value = googleAnalyticsAccounts.value.map(
      (account) => account.account_id
    );
  }
};

init();
</script>

<template>
  <div class="flex items-center justify-center min-h-screen bg-gray-100">
    <div class="bg-white shadow-md rounded-lg p-6 w-full max-w-xl">
      <div class="flex items-center justify-between mb-6">
        <h4
          class="text-lg font-medium text-gray-800 after:content-[''] after:block after:w-full after:h-[2px] after:bg-[#3b397f] after:mt-1"
        >
          Manage Google Accounts
        </h4>
      </div>
      <form ref="refVForm" @submit.prevent="onSubmit" class="space-y-6">
        <!-- Google Ads -->
        <div class="space-y-4">
          <AppAutocomplete
            v-model="selectedAccounts"
            class="w-full bg-white"
            :prepend-inner-icon="metaSvg"
            :items="reportStore.googleAdsAccounts"
            item-title="name"
            item-value="account_id"
            placeholder="Select Accounts you want to pull data from."
            label="Google Ads Accounts"
            single-line
            hide-details
            multiple
            clearable
          />
          <VBtn
            class="w-1/3 py-3 bg-secondary text-white font-medium rounded-md hover:bg-indigo-700"
            :loading="googleAdsLoading"
            @click="onGoogleAdsClick"
          >
            Connect Google Ads
          </VBtn>
        </div>

        <!-- Google Analytics -->
        <div class="space-y-4">
          <AppAutocomplete
            v-model="selectedGoogleAnalyticsAccounts"
            class="w-full bg-white"
            :prepend-inner-icon="metaSvg"
            :items="reportStore.googleAnalyticsAccounts"
            item-title="name"
            item-value="account_id"
            placeholder="Select Accounts you want to pull data from."
            label="Google Analytics Accounts"
            single-line
            hide-details
            multiple
            clearable
          />
          <VBtn
            class="w-1/3 py-3 bg-secondary text-white font-medium rounded-md hover:bg-indigo-700"
            :loading="googleAnalyticsLoading"
            @click="onGoogleAnalyticsClick"
          >
            Connect Google Analytics
          </VBtn>
        </div>

        <!-- Submit All -->
        <div class="space-y-4">
          <VBtn
            class="w-full py-3 bg-green-600 text-white font-medium rounded-md hover:bg-green-700"
            :loading="submitAllLoading"
            @click="onSubmitAll"
          >
            Submit All
          </VBtn>
        </div>
      </form>
    </div>
  </div>
</template>

<style scoped></style>
